import React from 'react';
import PropTypes from 'prop-types';

export const BtmCmos = ({ descrtiption, cmos }) => (
  <div className="btm_cmos">
    <p className="btm_cmos_description">{descrtiption}</p>
    <div className="btm_cmos_grid">
      {cmos.map(cmo => (
        <div key={cmo.name} className="btm_cmo_card">
          <img src={cmo.image} alt={`${cmo.name}`} className="btm_cmo_image" />
          <div className="btm_cmo_card_description">
            <p className="btm_cmo_card_name">{cmo.name}</p>
            <p className="btm_cmo_card_title">CMO</p>
            <p className="btm_cmo_card_company">{cmo.company}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

BtmCmos.propTypes = {
  descrtiption: PropTypes.string.isRequired,
  cmos: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      company: PropTypes.string
    })
  ).isRequired
};
